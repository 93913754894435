"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread2"));
var _schema = _interopRequireDefault(require("default-app/dist/common/schema"));
function _default(_ref) {
  var data = _ref.data;
  return (0, _objectSpread2["default"])({}, (0, _schema["default"])({
    data: data
  }));
}